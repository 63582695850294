







































































import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BSignUpEmailStep extends Vue {
  email = "";
  loading = false;
  errorMsg: string | TranslateResult = "";

  async nextStep(): Promise<void> {
    this.loading = true;
    let res;
    try {
      res = await this.$store.dispatch("register/validateEmail", this.email);
      if (res?.result === "VALID") {
        this.$store.dispatch("register/setEmail", this.email);
        this.$router.push({ name: "b-phone-step" });
      } else if (res?.result === "DUPLICATE") {
        this.errorMsg = this.$t("SignUp_Email_Duplication_Error");
        this.$track({ label: "bsignup_inputemail_error_emailexists" });
      } else if (res?.result === "INVALID_FORMAT") {
        this.errorMsg = this.$t("SignUp_Email_Format_Error");
      }
    } catch (e) {
      console.error(e);
      this.errorMsg = this.$t("General_NetworkError_Message");
      this.loading = false;
    }
    this.loading = false;
  }
  showTacModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("@/components/containers/TermsAndConditionsModal.vue")
    );
    this.$store.dispatch("modal/show");
  }
  showPdpModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("@/components/containers/DataTreatmentModal.vue")
    );
    this.$store.dispatch("modal/show");
  }
  mounted(): void {
    if (this.$store.state.register.emailOrPhone) {
      this.email = this.$store.state.register.emailOrPhone;
    }
  }
}
