var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{staticClass:"flex flex-col gap-4",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('h1',{staticClass:"font-medium text-3xl"},[_vm._v(_vm._s(_vm.$t("BSignUp_EmailStep_Title")))]),_c('BaseInputText',{attrs:{"rules":"email|required","autofocus":"","label":_vm.$t('PersonalData_Email_Label'),"errorMsg":_vm.errorMsg},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('p',{staticClass:"text-gray-400 mt-4 text-xs"},[_c('i18n',{attrs:{"path":"SignUp_Password_TermsAndConditions","tag":"p","for":"tos"},scopedSlots:_vm._u([{key:"tac",fn:function(){return [_c('BaseText',{directives:[{name:"track",rawName:"v-track:click",value:({
              label: 'bsignup_inputemail_click_termsandconditions',
            }),expression:"{\n              label: 'bsignup_inputemail_click_termsandconditions',\n            }",arg:"click"}],staticClass:"text-blue-600",attrs:{"link":"","tabindex":"-1"},on:{"click":_vm.showTacModal}},[_vm._v(" "+_vm._s(_vm.$t("SignUp_Password_TermsAndConditions_TAC"))+" ")])]},proxy:true},{key:"pdp",fn:function(){return [_c('BaseText',{directives:[{name:"track",rawName:"v-track:click",value:({
              label: 'bsignup_inputemail_click_personaldatamanagement',
            }),expression:"{\n              label: 'bsignup_inputemail_click_personaldatamanagement',\n            }",arg:"click"}],staticClass:"text-blue-600",attrs:{"link":"","tabindex":"-1"},on:{"click":_vm.showPdpModal}},[_vm._v(_vm._s(_vm.$t("SignUp_Password_TermsAndConditions_PDP"))+" ")])]},proxy:true}],null,true)})],1),_c('BaseButton',{directives:[{name:"track",rawName:"v-track:view",value:({
        label: 'bsignup_inputemail_view',
      }),expression:"{\n        label: 'bsignup_inputemail_view',\n      }",arg:"view"},{name:"track",rawName:"v-track:click",value:({
        label: 'bsignup_inputemail_click_next',
      }),expression:"{\n        label: 'bsignup_inputemail_click_next',\n      }",arg:"click"}],staticClass:"mt-4",attrs:{"loading":_vm.loading,"disabled":!valid,"primary":""},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t("General_Continue")))]),_c('div',{staticClass:"mt-6 text-center"},[_c('p',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("SignUp_AlreadyHaveAnAccount"))+" "),_c('router-link',{staticClass:"text-blue-600",attrs:{"to":"/iniciar-sesion"}},[_vm._v(_vm._s(_vm.$t("LogIn_Button")))])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }